import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FormService {
    showHideForm = new Subject<boolean>()

}

<h1><i class="icon-left-big" (click)="onClickGoBack()"></i> {{ ( options.canBuy ? "title.offerBuy" : "title.offer" ) | translate}}</h1>

<form class="contactform">
    <mat-form-field>
        <mat-label>{{"label.firstname" | translate}}</mat-label>
        <input matInput type="text" required="required" [(ngModel)]="contactData.firstname" name="firstname">
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{"label.lastname" | translate}}</mat-label>
        <input matInput type="text" required="required" [(ngModel)]="contactData.lastname" name="lastname">
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{"label.company" | translate}}</mat-label>
        <input matInput type="text" [(ngModel)]="contactData.company" name="company">
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{"label.city" | translate}}</mat-label>
        <input matInput type="text" required="required" [(ngModel)]="contactData.city" name="city">
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{"label.plz" | translate}}</mat-label>
        <input matInput type="text" required="required" [(ngModel)]="contactData.plz" name="postcode">
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{"label.street" | translate}}</mat-label>
        <input matInput type="text" required="required" [(ngModel)]="contactData.street" name="street">
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{"label.phone" | translate}}</mat-label>
        <input matInput type="tel" [(ngModel)]="contactData.phone" name="phone">
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{"label.mail" | translate}}</mat-label>
        <input matInput type="email" required="required" [(ngModel)]="contactData.mail" name="mail">
    </mat-form-field>

    <mat-form-field appearance="fill" class="full">
        <mat-label>{{"label.comment" | translate}}</mat-label>
        <textarea matInput [(ngModel)]="contactData.comment" name="comment"></textarea>
    </mat-form-field>

    <section class="full checkboxes">
        <mat-checkbox [checked]="contactData.checkbox" (change)="onCheckBoxChange($event)">{{"mail.klx.checkbox" | translate}}</mat-checkbox>
        <mat-checkbox [checked]="contactData.requestImages" (change)="contactData.requestImages = !contactData.requestImages">{{"mail.klx.images" | translate}}</mat-checkbox>
        <div *ngIf="contactData.requestImages" class="imageTypes checkboxes">
            <mat-checkbox (change)="onChangeImageType($event, 'croppedImages')">{{"label.croppedImages" | translate}}</mat-checkbox>
            <mat-checkbox (change)="onChangeImageType($event, 'applicationRenderings')">{{"label.applicationRenderings" | translate}}</mat-checkbox>
        </div>
        <mat-checkbox [checked]="contactData.newsletter" (change)="contactData.newsletter = !contactData.newsletter">{{"mail.klx.newsletter" | translate}}</mat-checkbox>
        <div *ngIf="contactData.newsletter" class="imageTypes checkboxes">
            <mat-form-field>
                <mat-label>{{"label.targetgroup" | translate}}</mat-label>
                <mat-select [(value)]="contactData.targetgroup">
                    <mat-option value="Interesse_Endkunde">{{"label.endcustomer" | translate}}</mat-option>
                    <mat-option value="Interesse_ProducFacts">{{"label.facts" | translate}}</mat-option>
                    <mat-option value="Interesse_Presse">{{"label.press" | translate}}</mat-option>
                    <mat-option value="Interesse_Architekt">{{"label.architect" | translate}}</mat-option>
                    <mat-option value="Interesse_Andere">{{"label.other" | translate}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <mat-checkbox class="privacy" [checked]="contactData.privacy" (change)="contactData.privacy = !contactData.privacy">
            <a target="_blank" href="https://www.kloeber.com/data-protection">{{"mail.klx.privacy" | translate}}*</a>
        </mat-checkbox>
    </section>

    <footer>
        <div *ngIf="mailStatus == 'open' || mailStatus == 'finish'" class="buttons">
            <button type="button" (click)="onClickRequestOffer()" mat-flat-button color="primary">{{"button.sendOffer" | translate}}</button>
            <button type="button" *ngIf="options.canBuy" (click)="onClickBuy()" mat-flat-button color="primary">{{"button.buy" | translate}}</button>
        </div>
        <div *ngIf="mailStatus == 'sending'" class="sending">
            {{"message.mailIsSending" | translate}}
        </div>

        <div *ngIf="message != '' && mailStatus != 'sending'" class="message">
            {{message}}
        </div>
    </footer>
</form>
<article>
    <header>
        <a href="https://sedus.com" target="_blank" class="logo">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88.5 40">
                <path d="M14.4 27.9c.5 1.1.8 2.5.8 4 0 2.6-.7 4.6-2 6C11.9 39.3 10 40 7.6 40c-2.4 0-4.3-.7-5.6-2-1.3-1.3-2-3.1-2-5.4v-3h4.4v2.7c0 1.1.3 2 .8 2.6.6.6 1.4.9 2.4.9s1.8-.3 2.4-1c.5-.6.8-1.6.8-2.8 0-.8-.2-1.6-.5-2.3-.4-.7-.9-1.4-1.7-2.1l-4.8-4.4C2.4 22 1.4 20.8.9 19.7c-.6-1-.9-2.3-.9-3.8 0-2.3.7-4.1 2-5.5 1.3-1.3 3.2-2 5.5-2s4.1.6 5.4 1.9c1.3 1.3 2 3 2 5.2V18h-4.5v-2.1c0-1.1-.3-1.9-.8-2.4-.5-.6-1.2-.8-2.2-.8-1 0-1.8.3-2.3.8-.5.6-.8 1.4-.8 2.4 0 .7.2 1.4.5 2 .3.6.9 1.3 1.8 2.1l4.9 4.5c1.5 1.1 2.4 2.2 2.9 3.4m16.7-17.5c1.4 1.3 2 3.1 2 5.3v6.2l-10.7 6.2v4.4c0 1.1.3 1.9.8 2.5.6.6 1.4.9 2.4.9s1.7-.3 2.3-.9c.5-.6.8-1.4.8-2.5v-2.8h4.4v3c0 2.2-.7 4-2 5.3-1.4 1.3-3.2 2-5.4 2-2.3 0-4.2-.7-5.6-2-1.4-1.3-2.1-3.1-2.1-5.3V15.8c0-2.2.7-4 2.1-5.3 1.4-1.3 3.2-2 5.5-2 2.3-.1 4.1.6 5.5 1.9m-2.4 5.4c0-1-.3-1.8-.8-2.4-.5-.6-1.3-.8-2.3-.8-1 0-1.8.3-2.4.8-.6.6-.8 1.4-.8 2.4v7.5l6.3-3.7v-3.8zM47.1 0h4.4v39.7h-4.1v-1.9c-.1.2-.2.3-.4.5-1 1.1-2.3 1.6-4 1.6-2 0-3.6-.7-4.8-2-1.2-1.3-1.8-3.1-1.8-5.4V15.8c0-2.3.6-4.1 1.8-5.4 1.2-1.3 2.8-2 4.8-2 1.8 0 3.1.5 4.1 1.5V0zm0 15.9c0-1-.3-1.8-.8-2.4-.6-.6-1.3-.9-2.3-.9-1 0-1.8.3-2.3.9-.6.6-.8 1.4-.8 2.4v16.6c0 1 .3 1.8.8 2.4.6.6 1.3.9 2.3.9 1 0 1.8-.3 2.3-.9.6-.6.8-1.4.8-2.4V15.9zm18.7 16.6c0 1-.3 1.8-.8 2.4-.5.6-1.3.9-2.3.9-1 0-1.8-.3-2.4-.9-.6-.6-.8-1.4-.8-2.4V8.7H55v24c0 2.2.7 4 2.1 5.3 1.4 1.3 3.2 2 5.6 2 2.3 0 4.1-.7 5.5-2 1.4-1.3 2.1-3.1 2.1-5.3v-24h-4.5v23.8zm21.9-4.6c-.5-1.1-1.5-2.3-2.8-3.5L80 19.9c-.8-.8-1.4-1.5-1.8-2.1-.3-.6-.5-1.3-.5-2 0-1 .3-1.8.8-2.4.5-.6 1.3-.8 2.3-.8 1 0 1.7.3 2.2.8.5.6.8 1.4.8 2.4V18h4.5v-2.4c0-2.2-.7-3.9-2-5.2-1.3-1.3-3.2-1.9-5.4-1.9-2.3 0-4.2.7-5.5 2-1.3 1.3-2 3.2-2 5.5 0 1.5.3 2.7.8 3.9.6 1.1 1.5 2.3 2.9 3.5l4.8 4.4c.8.7 1.3 1.4 1.7 2.1.4.7.5 1.4.5 2.3 0 1.2-.3 2.2-.8 2.8-.6.6-1.3 1-2.4 1-1 0-1.8-.3-2.4-.9-.6-.6-.8-1.5-.8-2.6v-2.7h-4.4v3c0 2.3.7 4.1 2 5.4 1.3 1.3 3.2 2 5.6 2 2.4 0 4.2-.7 5.6-2.1 1.3-1.4 2-3.4 2-6 0-1.8-.3-3.1-.8-4.2"></path>
            </svg>
        </a>
        <h1>{{"title.interactiveAB" | translate}}</h1>
        <div>
            <app-language-switch></app-language-switch>
        </div>
    </header>
    <app-eaiws-base-layout [optionsInput]="options"></app-eaiws-base-layout>
    <footer>
        <a target="_blank" [href]="'url.contact' | translate">{{"label.contact" | translate}}</a>
        <a target="_blank" [href]="'url.imprint' | translate">{{"label.imprint" | translate}}</a>
        <a target="_blank" [href]="'url.privacyPolicy' | translate">{{"label.privacyPolicy" | translate}}</a>
    </footer>
</article>
import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { ArticleData, ArticleProperties, ChoiceList, ItemProperties, Property } from '@egr/wcf/modules/eaiws/basket';
import { BasketFolderService } from 'src/app/services/basket-folder.service';
import { EaiwsService } from 'src/app/services/eaiws.service';
import { PropertyService } from 'src/app/services/property.service';
import { ConfigurationConfig } from '../../eaiws/eaiwsBaseComponent';
import { EaiwsOptions } from 'src/app/models/options';
import { LanguageService } from 'src/app/services/language.service';

@Component({
    selector: 'app-property-ui',
    templateUrl: './property-ui.component.html',
    styleUrls: ['./property-ui.component.scss']
})
export class PropertyUIComponent implements OnInit, OnDestroy {

    @Input() options: EaiwsOptions = {
        showPriceOptions: false,
        showDiscountPrice: false,
        showConfiguration: true,
        showPrice: true,
        showSALText: false,
        restrictConfiguration: false,
        allowedConfiguration: [],
        canConfigured: false
    }

    articleData: ArticleData | undefined
    choiceLists: ChoiceListProperty[] = []
    itemID = ""
    hasMetaProperties = false

    private itemClicked
    private openPropertyEvent
    private clickedChoiceEvent


    constructor(public eaiwsService: EaiwsService, public folderService: BasketFolderService, private propertyService: PropertyService, private languageService: LanguageService) {
        this.itemClicked = this.folderService.itemClickedEmitter.subscribe((itemID) => this.changedSelectedItem(itemID))

        this.openPropertyEvent = propertyService.openProperty.subscribe(prop => {
            if (!prop) {
                this.choiceLists.forEach(val => val.show = true)
                return
            }

            const currentProp = this.choiceLists.filter(item => item.choiceList.propName != prop.propName)
            currentProp.forEach(val => val.show = false)
        })

        this.clickedChoiceEvent = propertyService.clickedChoice.subscribe(propValue => {
            this.changedSelectedItem(this.itemID)
        })

        languageService.changedLanguage.subscribe(lang => {
            this.loadChoiceList()
        })

        this.changedSelectedItem(this.folderService.currentItemID)
    }


    ngOnInit(): void {

    }

    changedSelectedItem(itemID: string) {
        this.itemID = itemID

        this.loadChoiceList()
    }

    ngOnDestroy(): void {
        this.itemClicked.unsubscribe
    }

    async loadChoiceList() {
        if (!this.itemID) return

        this.choiceLists = []
        this.hasMetaProperties = false
        this.articleData = await this.eaiwsService.eaiwsSession.basket.getArticleData(this.itemID)
        let choiceLists = await this.eaiwsService.eaiwsSession.basket.getAllChoiceLists(this.itemID)

        if (this.options.restrictConfiguration && this.options.allowedConfiguration) {
            const article = this.articleData.baseArticleNumber ?? ""

            this.options.allowedConfiguration.forEach(config => {
                if (config.baseAticleNumber.indexOf(article) == -1) return

                //Properties und derren Werte Filtern
                choiceLists = choiceLists.filter(choice => {
                    const restriction = config.settings.find(item => item.key == choice.propName)
                    if (restriction) {
                        if (!choice.values) return false

                        choice.values = choice.values.filter(val => restriction.values.find(item => item == val.value || item == '*'))
                        return true
                    } else {
                        return false
                    }
                })
            })
        }

        choiceLists = choiceLists.sort((a, b) => {
            if (a.propClass == "MetaProperties") {
                this.hasMetaProperties = true
                return -1
            } else {
                return 1
            }
        })

        const properties = this.articleData.properties
        if (!properties) return

        choiceLists.forEach(list => {
            this.choiceLists.push({
                choiceList: list,
                properties: properties,
                show: true
            })
        })
    }

    getClass() {
        return "articleDetails " + this.options.designClass
    }

}


export interface ChoiceListProperty {
    choiceList: ChoiceList,
    properties: Property[],
    show: boolean
}

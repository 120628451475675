import { Component, Injector } from '@angular/core';
import { ParasLanguage } from 'src/app/app-routing.module';
import { PageW3codeBase } from '../page-w3code-base';

@Component({
    selector: 'app-page-language',
    templateUrl: './page-language.component.html',
    styleUrls: ['./page-language.component.scss']
})
export class PageLanguageComponent extends PageW3codeBase {

    constructor(private injector: Injector) {
        super(injector)


    }
}

import { Component, Injector } from '@angular/core';
import { AddressData, ContactData, CommAddress } from '@egr/wcf/modules/eaiws/project';
import { MesseUIConfig } from 'src/app/components/catalog/messe-ui/messe-ui.component';
import { BasketFolderService } from 'src/app/services/basket-folder.service';
import { EaiwsService } from 'src/app/services/eaiws.service';
import { PageW3codeBase } from '../page-w3code-base';
import { EaiwsOptions } from 'src/app/models/options';
import { environment } from 'src/environments/environment';
import { Manufacturer } from 'src/app/models/manufacturer';


@Component({
    selector: 'app-page-kloeber-messe',
    templateUrl: './page-kloeber-messe.component.html',
    styleUrls: ['./page-kloeber-messe.component.scss']
})
export class PageKloeberMesseComponent extends PageW3codeBase {
    showFormular = false
    callToActionEvent
    showHideFormEvent
    message = ""
    contactData = {
        firstname: "",
        lastname: "",
        company: "",
        city: "",
        plz: "",
        street: "",
        phone: "",
        comment: "",
        mail: ""
    }
    messeConfig: MesseUIConfig

    options: EaiwsOptions = {
        restrictConfiguration: true,
        allowedConfiguration: [],
        showCatalog: false,
        canArticleDelete: true,
        canConfigured: true,
        canBuy: true,
        showCallToAcion: true,
        showPrice: true,
        showDiscountPrice: true,
        backend: environment.backendBaseUrlKloeber,
        manufacturer: Manufacturer.kloeber,
        showBreadcrump: false,
        designClass: "kloeber",
        showPriceOptions: true
    }

    constructor(private injector: Injector, private basketService: BasketFolderService, private eaiwsService: EaiwsService) {
        super(injector)

        this.callToActionEvent = this.basketService.callToActionEmitter.subscribe(() => {
            this.formService.showHideForm.next(true)
        })

        this.showHideFormEvent = this.formService.showHideForm.subscribe(show => {
            this.showFormular = show
        })

        this.messeConfig = {
            messeName: "imm",
            package: [{
                file: "Messepaket_1.obx",
                image: "assets/img/Messepaket1.jpg",
                name: "Comeet-Paket"
            }, {
                file: "Messepaket_2.obx",
                image: "assets/img/Messepaket2.jpg",
                name: "Lim-Paket"
            }, {
                file: "Messepaket_3.obx",
                image: "assets/img/Messepaket3.jpg",
                name: "Wooom-Paket"
            }]
        }

        this.options.allowedConfiguration = [{
            baseAticleNumber: ["lim98"],
            settings: [{
                key: "K__Ausfuehrung__S",
                values: [
                    "*"
                ]
            }, {
                key: "K__Stoff__Sitz",
                values: [
                    "*"
                ]
            }, {
                key: "K__X__Bezugsgruppe__Sitz",
                values: [
                    "Leder_premium_Plus",
                    "Remix_3",
                    "Steelcut_Trio_3",
                    "Comfort_Plus",
                    "Evida",
                    "Cura",
                    "Step/Step_Melange",
                    "Malta",
                    "Fame",
                    "Era",
                    "Nova",
                    "Chicago"
                ]
            }, {
                key: "K__AL",
                values: [
                    "S3524",
                    "S3523",
                    "S3533",
                    "S3534"
                ]
            }]
        }, {
            baseAticleNumber: ["woo75", "woo72"],
            settings: [{
                key: "K__Ausfuehrung__S",
                values: [
                    "*"
                ]
            }, {
                key: "K__Stoff__Sitz",
                values: [
                    "*"
                ]
            }, {
                key: "K__X__Bezugsgruppe__Sitz",
                values: [
                    "Leder_premium_Plus",
                    "Remix_3",
                    "Steelcut_Trio_3",
                    "Comfort_Plus",
                    "Evida",
                    "Cura",
                    "Step/Step_Melange",
                    "Malta",
                    "Fame",
                    "Era",
                    "Nova",
                    "Chicago"
                ]
            }]
        }, {
            baseAticleNumber: ["com55"],
            settings: [{
                key: "K_Sitzschale_Oberflaeche",
                values: [
                    "S9010",
                    "S9060",
                    "S9045",
                    "S9020",
                    "S9090"
                ]
            }, {
                key: "K_Lehnenschale_Oberflaeche",
                values: [
                    "S9010",
                    "S9060",
                    "S9045",
                    "S9020",
                    "S9090"
                ]
            }, {
                key: "K_Gestell",
                values: [
                    "S3265",
                    "S3267"
                ]
            }]
        }, {
            baseAticleNumber: ["com66"],
            settings: [{
                key: "K_Sitzschale_Oberflaeche",
                values: [
                    "S9010",
                    "S9060",
                    "S9045",
                    "S9020",
                    "S9090"
                ]
            }, {
                key: "K_Lehnenschale_Oberflaeche",
                values: [
                    "S9010",
                    "S9060",
                    "S9045",
                    "S9020",
                    "S9090",
                    "S9324",
                    "S9323"
                ]
            }, {
                key: "K_Polsterung",
                values: [
                    "P000",
                    "P880"
                ]
            }, {
                key: "K_Fuss_Oberflaeche",
                values: [
                    "S9218",
                    "S9232"
                ]
            }, {
                key: "K_Stoff_Sitz",
                values: [
                    "*"
                ]
            }, {
                key: "K_X_Bezugsgruppe_Sitz",
                values: [
                    "Leder_premium_Plus",
                    "Remix_3",
                    "Steelcut_Trio_3",
                    "Comfort_Plus",
                    "Evida",
                    "Cura",
                    "Step/Step_Melange",
                    "Malta",
                    "Fame",
                    "Era",
                    "Nova",
                    "Chicago"
                ]
            }, {
                key: "K_AL",
                values: [
                    "*"
                ]
            }

            ]
        }, {
            baseAticleNumber: ["com55h"],
            settings: [{
                key: "K_Sitzschale_Oberflaeche",
                values: [
                    "S9010",
                    "S9060",
                    "S9045",
                    "S9020",
                    "S9090"
                ]
            }, {
                key: "K_Lehnenschale_Oberflaeche",
                values: [
                    "S9010",
                    "S9060",
                    "S9045",
                    "S9020",
                    "S9090"
                ]
            }, {
                key: "K_Fuss_Oberflaeche",
                values: [
                    "*"
                ]
            }]
        }, {
            baseAticleNumber: ["com25"],
            settings: [{
                key: "K_Beizton",
                values: [
                    "S5111",
                    "S5110"
                ]
            }]
        },]
    }




    onClickGoBack() {
        this.showFormular = false
    }


    async onClickRequestOffer() {
        //Kontaktdaten in die OBk speichern
        const commAdressMail = new CommAddress()
        commAdressMail.scope = "Business"
        commAdressMail.type = "EMail"
        commAdressMail.value = this.contactData.mail

        const commAdressPhone = new CommAddress()
        commAdressPhone.scope = "Business"
        commAdressPhone.type = "Phone"
        commAdressPhone.value = this.contactData.phone

        const contact = new ContactData()
        contact.contactType = "Client"
        contact.firstName = this.contactData.firstname
        contact.lastName = this.contactData.lastname
        contact.commAddresses = [commAdressPhone, commAdressMail]

        const address = new AddressData()
        address.addressType = "SoldTo"
        address.name1 = this.contactData.company
        address.street = this.contactData.street
        address.postalCode = this.contactData.plz
        address.contacts = [contact]
        await this.eaiwsService.eaiwsSession.project.setAddressData(address)

        //OBK laden
        const url = await this.eaiwsService.eaiwsSession.session.saveSession(null)
        const fileData = await this.toDataUrl(url)

        //Mail versenden
        const settings: mail = {
            to: "kontakt@w3code.de",
            subject: this.translateService.instant("mail.klx.subject"),
            body: `<table>
                <tr>
                    <td>${this.translateService.instant("label.firstname")}:</td>
                    <td>${this.contactData.firstname}</td>
                </tr>
                <tr>
                    <td>${this.translateService.instant("label.lastname")}:</td>
                    <td>${this.contactData.lastname}</td>
                </tr>
                <tr>
                    <td>${this.translateService.instant("label.company")}:</td>
                    <td>${this.contactData.company}</td>
                </tr>
                <tr>
                    <td>${this.translateService.instant("label.city")}:</td>
                    <td>${this.contactData.city}</td>
                </tr>
                <tr>
                    <td>${this.translateService.instant("label.plz")}:</td>
                    <td>${this.contactData.plz}</td>
                </tr>
                <tr>
                    <td>${this.translateService.instant("label.street")}:</td>
                    <td>${this.contactData.street}</td>
                </tr>
                <tr>
                    <td>${this.translateService.instant("label.phone")}:</td>
                    <td>${this.contactData.phone}</td>
                </tr>
                <tr>
                    <td>${this.translateService.instant("label.mail")}:</td>
                    <td>${this.contactData.mail}</td>
                </tr>
                <tr>
                    <td>${this.translateService.instant("label.comment")}:</td>
                    <td>${this.contactData.comment}</td>
                </tr>
            </table>`,
            attachmet: fileData.split(",")[1]
        }


        const result = await fetch("https://configurator.kloeber.com/Backend/klx/mail", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(settings),
        })

        if (result.status == 200) {
            this.message = this.translateService.instant("success.sendOffer")
        } else {
            this.message = this.translateService.instant("error.sendOffer")
        }
    }


    toDataUrl(url: string) {
        return new Promise<string>((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.onload = function () {
                const reader = new FileReader();
                reader.onloadend = function () {
                    resolve(typeof reader.result == 'string' ? reader.result : "");
                }
                reader.readAsDataURL(xhr.response);
            };
            xhr.open('GET', url);
            xhr.responseType = 'blob';
            xhr.send();
        })
    }
}



export interface mail {
    to: string
    attachmet: string
    subject: string
    body: string
}

<div class="catalog" [className]="getClassName()">
    <h2 class="breadcrump" *ngIf="options.showBreadcrump">
        <img class="logo" *ngIf="options.logo" [src]="'assets/img/' + options.logo" alt="Logo" />
        <div class="crump" *ngFor="let crump of currentPath" (click)="onClickBreadCrump(crump)">{{crump.label}}</div>
    </h2>

    <div class="items">
        <div *ngFor="let item of items" class="item" (click)="onClickIten(item)">
            <div class="name">{{item.label}}</div>
            <img [src]="item.icon" />
        </div>
    </div>
</div>
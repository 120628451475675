import { Component, Injector } from '@angular/core';
import { PageW3codeBase } from '../page-w3code-base';

@Component({
    selector: 'app-page-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent extends PageW3codeBase {

    constructor(private injector: Injector) {
        super(injector)
    }
}

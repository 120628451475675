

export function convertUTF8Text(text: string) {
    try {
        return decodeURIComponent(escape(text))
    } catch (error) {
        return text
    }

}

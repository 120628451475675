import { Component, Injector } from '@angular/core';
import { ParasConfigurator } from 'src/app/app-routing.module';
import { BasketFolderService } from 'src/app/services/basket-folder.service';
import { PageW3codeBase } from '../page-w3code-base';
import { EaiwsOptions } from 'src/app/models/options';
import { environment } from 'src/environments/environment';
import { Manufacturer } from 'src/app/models/manufacturer';

@Component({
    selector: 'app-page-sedus-configurator',
    templateUrl: './page-sedus-configurator.component.html',
    styleUrls: ['./page-sedus-configurator.component.scss']
})
export class PageSedusConfiguratorComponent extends PageW3codeBase {
    showFormular = false
    callToActionEvent
    showHideFormEvent

    options: EaiwsOptions = {
        showPrice: false,
        showPriceOptions: true,
        showDiscountPrice: true,
        showCallToAcion: true,
        showSALText: true,
        canConfigured: true,
        canArticleDelete: true,
        canDownloadOBX: true,
        canPriceDisableEnable: true,
        showCatalog: true,
        disabledSeries: [],
        productPreSelect: "",
        backend: environment.backendBaseUrlKloeber,
        logo: "LogoSedus.png",
        showBreadcrump: true,
        manufacturer: Manufacturer.sedus,
        catalogPreSelect: ""
    }

    constructor(private injector: Injector, private basketService: BasketFolderService) {
        super(injector)

        this.activatedRoute.params.subscribe(paras => {
            const parameter = <ParasConfigurator>paras

            this.options.catalogPreSelect = parameter.productFamily ? parameter.productFamily : ""
            this.options.productPreSelect = parameter.product ? parameter.product : ""
            this.options.obxPreSelect = parameter.obxName ? parameter.obxName : ""
        })

        this.optionsService.optionsChnaged.subscribe(options => {
            this.options = options
        })

        this.callToActionEvent = this.basketService.callToActionEmitter.subscribe(() => {
            this.showFormular = true
        })

        this.showHideFormEvent = this.formService.showHideForm.subscribe(show => {
            this.showFormular = show
        })
    }
}

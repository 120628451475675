<div [className]="getClass()">
    <div *ngIf="currentProperty && !open" class="property" (click)="onClickProperty()">
        <span class="group">{{currentProperty.propText}}:</span>
        <span class="value">
            <img class="propImage" *ngIf="currentProperty.value.smallIcon != ''" [src]="currentProperty.value.smallIcon" [alt]="currentProperty.value.text" />
            {{currentProperty.value.text}}
        </span>
    </div>


    <div *ngIf="choiceListProperty && currentProperty && open" class="choiceList">
        <h3 (click)="onClickCloseChoiceList()"><i class="icon-left-big"></i>{{currentProperty.propText}}</h3>
        <div [class]="choicesClass">
            <div *ngFor="let item of choiceListProperty.choiceList.values" class="choice" (click)="onClickChoice(item)">
                <img class="propImage" *ngIf="item.smallIcon != ''" [src]="item.smallIcon" [alt]="item.text" />
                <span class="propText" *ngIf="item.smallIcon == ''">{{item.text}}</span>
            </div>
        </div>

    </div>
</div>
import { Component, ElementRef, OnInit, ViewChild, OnDestroy, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DownloadAction, DownloadActionImage, DownloadType } from 'src/app/models/downloadAction';
import { EaiwsOptions } from 'src/app/models/options';
import { BasketFolderService } from 'src/app/services/basket-folder.service';
import { EaiwsService } from 'src/app/services/eaiws.service';
import { OptionsService } from 'src/app/services/options.service';
import { ViewerService } from 'src/app/services/viewer.service';

@Component({
    selector: 'app-viewer-ui',
    templateUrl: './viewer-ui.component.html',
    styleUrls: ['./viewer-ui.component.scss']
})
export class ViewerUIComponent implements OnInit, OnDestroy {

    @ViewChild('downloads') downloads: ElementRef<HTMLDivElement> | undefined;
    @ViewChild('downloadIcon') downloadIcon: ElementRef<HTMLDivElement> | undefined;
    @ViewChild('viewer') viewer: ElementRef<HTMLDivElement> | undefined;

    @Input() options: EaiwsOptions = {
        canPriceDisableEnable: false,
        canDownloadOBX: false
    }

    onItemClickedEvent
    onResizeViewerEvent
    onShowLoadingEvent
    itemID = ""
    downloadsWidth = 50
    open = false
    downloadActions: DownloadAction[] = []
    isControlPressed = false
    isDimonsionBoxActive = false
    isMovingActive = false
    isLoading = false
    isShowPrice = false

    constructor(private basketFolderService: BasketFolderService, private eaiwsService: EaiwsService, private viewerService: ViewerService, private translationService: TranslateService, private optionsService: OptionsService) {

        this.onItemClickedEvent = this.basketFolderService.itemClickedEmitter.subscribe(itemID => {
            this.itemID = itemID

            this.downloadActions = [
                new DownloadActionImage(this.translationService.instant("label.image"), DownloadType.Image, this.eaiwsService.eaiwsSession.basket, this.itemID),
                new DownloadAction("3DS", DownloadType.DS3, this.eaiwsService.eaiwsSession.basket, this.itemID),
                new DownloadAction("DWG", DownloadType.DWG, this.eaiwsService.eaiwsSession.basket, this.itemID),
                new DownloadAction("FBX", DownloadType.FBX, this.eaiwsService.eaiwsSession.basket, this.itemID)
            ]


            if (this.itemID != "" && this.viewer) {
                this.viewer.nativeElement.style.minHeight = "80vh"
            }
        })

        this.onResizeViewerEvent = this.viewerService.eventDoResizeViewer.subscribe(() => {
            if (!this.viewer) return

            this.viewer.nativeElement.style.height = "10px"
            this.eaiwsService.coreApp.viewer.resize(true)

            setTimeout(() => {
                if (!this.viewer) return

                this.viewer.nativeElement.style.height = "100%"
                this.eaiwsService.coreApp.viewer.resize(true)
            }, 200)

        })

        this.onShowLoadingEvent = this.viewerService.showLoading.subscribe(showLoading => {
            this.isLoading = showLoading
        })
    }

    ngOnInit(): void {
        this.downloadsWidth = this.downloadIcon ? this.downloadIcon.nativeElement.scrollWidth : 55

        this.disableScrollOnViewer()
    }

    ngOnDestroy(): void {
        this.onItemClickedEvent.unsubscribe()
        this.onResizeViewerEvent.unsubscribe()
        this.onShowLoadingEvent.unsubscribe()
    }

    onClickDownload() {
        if (!this.downloads) return
        if (!this.downloadIcon) return

        this.downloadsWidth = this.open ? this.downloadIcon.nativeElement.scrollWidth : this.downloads.nativeElement.scrollWidth
        this.open = !this.open
    }

    onClickAction(action: DownloadAction) {
        void action.do()

        this.onClickDownload()
    }

    onClickDimonsion() {
        if (!this.eaiwsService.articleManager) return

        this.eaiwsService.articleManager.switchDimonsionBox()
        this.isDimonsionBoxActive = this.eaiwsService.articleManager.isDimonsonBoxActive()
    }

    onClickMove() {
        this.viewerService.setMovingAllowed(!this.viewerService.isMovingAllowed())
        this.isMovingActive = this.viewerService.isMovingAllowed()
    }

    onClickShowPrice() {
        this.isShowPrice = !this.isShowPrice

        this.options.showPrice = this.isShowPrice

        this.optionsService.optionsChnaged.next(this.options)
    }


    private disableScrollOnViewer() {
        const viewer = document.getElementById('viewer')
        if (!viewer) return

        viewer.addEventListener("pointerdown", event => { this.handleKeyDown(event) })
        viewer.addEventListener("pointerup", event => { this.handleKeyUp(event) })
        document.addEventListener("keydown", event => {
            if (event.key == "Control" && !this.isControlPressed) {
                this.handleKeyDown(event)
            }
        })

        document.addEventListener("keyup", event => {
            if (event.key == "Control" && this.isControlPressed) {
                this.handleKeyUp(event)
            }
        })

        viewer.addEventListener("wheel", event => {
            if (this.isControlPressed) {
                event.preventDefault()
                event.stopPropagation()
            }

        }, false);
    }

    handleKeyDown(event: Event) {
        this.isControlPressed = true
        this.viewerService.setZoomAllowed(true)
    }
    handleKeyUp(event: Event) {
        this.isControlPressed = false
        this.viewerService.setZoomAllowed(false)
    }
}


import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Property, PropertyValue } from '@egr/wcf/modules/eaiws/basket';
import { EaiwsService } from 'src/app/services/eaiws.service';
import { PropertyService } from 'src/app/services/property.service';
import { ViewerService } from 'src/app/services/viewer.service';
import { ChoiceListProperty } from '../property-ui/property-ui.component';
import { convertUTF8Text } from 'src/app/helper';
import { EaiwsOptions } from 'src/app/models/options';

@Component({
    selector: 'app-property-single',
    templateUrl: './property-single.component.html',
    styleUrls: ['./property-single.component.scss']
})
export class PropertySingleComponent implements OnInit, OnDestroy {

    @Input() options: EaiwsOptions = {
        canConfigured: false
    }
    @Input() choiceListProperty: ChoiceListProperty | undefined
    @Input() itemID: string = ""

    open = false
    currentProperty: Property | undefined
    allChoicesHasImages = false
    choicesClass = ["choices"]

    // openPropertyEvent

    constructor(private propertyService: PropertyService, public eaiwsService: EaiwsService, private viewerService: ViewerService) {

    }

    ngOnInit(): void {
        if (!this.choiceListProperty) return

        this.currentProperty = this.choiceListProperty.properties.find((curProp) => curProp.propName == this.choiceListProperty?.choiceList.propName)

        if (!this.choiceListProperty.choiceList.values || !this.currentProperty) return

        this.choiceListProperty.choiceList.values.sort((a, b) => {
            if (!this.currentProperty) return -1
            return b.value == this.currentProperty.value.value ? 1 : -1
        })

        this.currentProperty.propText = convertUTF8Text(this.currentProperty.propText)
        this.currentProperty.value.text = convertUTF8Text(this.currentProperty.value.text)

        //Prüfen ob alle Auswahlmöglichkeietn Bilder haben
        const choicesWithImage = this.choiceListProperty.choiceList.values.filter(val => val.smallIcon != "")
        if (choicesWithImage.length == this.choiceListProperty.choiceList.values.length) {
            this.allChoicesHasImages = true
            this.choicesClass.push("onlyImages")
        }
    }
    ngOnDestroy(): void {
        //this.openPropertyEvent.unsubscribe()
    }

    onClickProperty() {
        if (!this.currentProperty) return
        if (!this.options.canConfigured) return

        this.open = true
        this.propertyService.openProperty.next(this.currentProperty)
    }

    onClickCloseChoiceList() {
        this.open = false
        this.propertyService.openProperty.next(undefined)
    }

    async onClickChoice(propValue: PropertyValue) {
        if (!this.currentProperty || !this.eaiwsService.articleManager) return

        await this.eaiwsService.eaiwsSession.basket.setPropertyValue(this.itemID, this.currentProperty.propClass, this.currentProperty.propName, propValue.value)
        await this.eaiwsService.articleManager.updateGeometry()
        this.viewerService.resetCamera()
        this.propertyService.clickedChoice.next(propValue)
        //this.onClickCloseChoiceList()
    }

    getClass() {
        return "wrapper " + this.options.designClass
    }
}

import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'online-ab-checker';

    constructor(private translate: TranslateService) {
        translate.setDefaultLang('de');
        translate.use('de');

        translate.get("title.title").subscribe((res: string) => {
            this.title = res
        })
    }
}

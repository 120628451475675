import { Injector, Inject } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ParasLanguage } from "../app-routing.module";
import { FormService } from "../services/form.service";
import { LanguageService } from "../services/language.service";
import { OptionsService } from "../services/options.service";

export class PageW3codeBase {
    protected translateService: TranslateService
    protected activatedRoute: ActivatedRoute
    protected formService: FormService
    protected languageService: LanguageService
    protected optionsService: OptionsService

    constructor(@Inject(Injector) injector: Injector) {
        this.translateService = injector.get(TranslateService)
        this.activatedRoute = injector.get(ActivatedRoute)
        this.formService = injector.get(FormService)
        this.languageService = injector.get(LanguageService)
        this.optionsService = injector.get(OptionsService)

        if (this.activatedRoute.parent && this.activatedRoute.pathFromRoot.length == 2) {
            this.activatedRoute.params.subscribe(paras => this.loadLanguage(paras))
        }
    }

    loadLanguage(paras: Params) {
        const parameter = <ParasLanguage>paras
        const lang = parameter.language ? parameter.language : "en"

        this.languageService.setLanguage(lang)
    }
}

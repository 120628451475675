import { ExpressionStatement } from '@angular/compiler';
import { Component } from '@angular/core';
import { CatalogUIComponent } from '../catalog-ui/catalog-ui.component';
import { CatalogItem } from '@egr/wcf/modules/eaiws/catalog';
import { EaiwsService } from 'src/app/services/eaiws.service';
import { CatalogService } from 'src/app/services/catalog.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-catalog-ui-list',
    templateUrl: './catalog-ui-list.component.html',
    styleUrls: ['./catalog-ui-list.component.scss']
})
export class CatalogUiListComponent extends CatalogUIComponent {

    constructor(eaiwsService: EaiwsService, catalogService: CatalogService, translateService: TranslateService) {
        super(eaiwsService, catalogService, translateService)

        this.eventCatalogListItemClicked.unsubscribe()
    }

    override onClickIten(item: CatalogItem) {
        this.catalogService.clickListCatalogItem.next(item)
    }

    override getCurrentPath() {
        return [this.currentPath[0]]
    }
}

import { Injectable } from '@angular/core';
import { Property, PropertyValue } from '@egr/wcf/modules/eaiws/basket';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PropertyService {
    openProperty = new Subject<Property | undefined>()
    clickedChoice = new Subject<PropertyValue>()
    priceChanged = new Subject<boolean>()
}

<div class="options">
    <div *ngIf="options.showPriceOptions" class="priceOptions">
        <div *ngIf="canConfigured()">
            <mat-form-field>
                <mat-label>{{"label.amount" | translate}}</mat-label>
                <input matInput min="1" type="number" name="amount" [ngModel]="articleAmount" (ngModelChange)="onChangeAmount($event)">
            </mat-form-field>
            <mat-form-field *ngIf="options.showDiscountPrice">
                <mat-label>{{"label.discount" | translate}}</mat-label>
                <input matInput min="0" max="100" type="number" name="discount" [ngModel]="articleDiscount" (ngModelChange)="onChangeDiscount($event)">
            </mat-form-field>
        </div>
        <div *ngIf="!canConfigured()">
            {{"label.amount" | translate}}: {{articleAmount}}<br />
            <span *ngIf="options.showDiscountPrice">
                {{"label.discount" | translate}}: {{articleDiscount | currency:'%':'symbol':'1.0-0':'de-DE'}}
            </span>
        </div>
    </div>

    <mat-form-field *ngIf="options.showSALText">
        <mat-label>{{"label.saltext" | translate}}</mat-label>
        <textarea matInput type="string" name="discount" [ngModel]="articleSAL" (ngModelChange)="onChangeSAL($event)"></textarea>
    </mat-form-field>

    <div *ngIf="options.showPrice" class="price">
        {{"label.netPrice" | translate}}: {{articlePrice | currency:'EUR':'symbol':'1.2-2':'de-DE'}}<br />
        <span *ngIf="options.showDiscountPrice">
            {{"label.discountPrice" | translate}}: {{articleDiscountedPrice | currency:'EUR':'symbol':'1.2-2':'de-DE'}}
        </span>
    </div>
</div>
<div class="outerSwitch">
    <div class="switch">
        <div class="currentLanguage language" (click)="onClickCurrentLanguage()">
            <span>{{currentLang.name}}</span>
        </div>
        <div [style.height.px]="height" class="allLanguage">
            <div class="language" *ngFor="let lang of languages" [class.hide]="lang.id == currentLang.id" (click)="onClickLanguage(lang)">
                {{lang.name}}
            </div>
        </div>
    </div>
</div>
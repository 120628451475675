import { Component, HostListener, Input, OnDestroy, ViewChild, ElementRef, OnInit, Injector } from '@angular/core';
import { EaiwsOptions } from 'src/app/models/options';
import { EaiwsBaseComponent } from '../eaiwsBaseComponent';

@Component({
    selector: 'app-eaiws-base-layout',
    templateUrl: './eaiws-base-layout.component.html',
    styleUrls: ['./eaiws-base-layout.component.scss']
})
export class EaiwsBaseLayoutComponent extends EaiwsBaseComponent implements OnDestroy, OnInit {


    @Input() optionsInput: EaiwsOptions = {
        orderNumber: "",
        showCatalog: false,
        showPrice: false,
        showPriceOptions: false,
        canArticleDelete: false,
        canConfigured: false,
        catalogPreSelect: "",
        showCallToAcion: false,
        productPreSelect: "",
        restrictConfiguration: false,
        allowedConfiguration: [],
        disabledSeries: [],
        showBreadcrump: true,
        backend: ""
    }


    @ViewChild("viewerUI") set content(content: ElementRef) {
        this.viewerUI = content

        if (!this.finishedLoading) return
        this.init()
    }



    constructor(public injector: Injector) {
        super(injector)
    }

    ngOnInit(): void {
        this.options = this.optionsInput

        void this.init()
    }

    ngOnDestroy(): void {
        this.newArticleAdded.unsubscribe()
        this.itemClickedEvent.unsubscribe()
        this.reloadBasketItemsEvent.unsubscribe()
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: Event) {
        if (!event.target) return

        const window = <Window>event.target
        this.checkWindowWidth(window.innerWidth)
    }
}

import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ArticleData } from '@egr/wcf/modules/eaiws/basket';
import { EaiwsOptions } from 'src/app/models/options';
import { ContactData, CategoryWrapperCustom, Result } from 'src/app/models/pconFacts/pconFactsTypes';
import { BasketFolderService } from 'src/app/services/basket-folder.service';
import { EaiwsService } from 'src/app/services/eaiws.service';
import { PconFactsService } from 'src/app/services/pconFacts.service';

@Component({
    selector: 'app-pcon-facts-ui',
    templateUrl: './pcon-facts-ui.component.html',
    styleUrls: ['./pcon-facts-ui.component.scss']
})
export class PconFactsUiComponent implements OnInit, OnDestroy {

    categories: CategoryWrapperCustom[] = []

    @Input() options: EaiwsOptions = {

    }

    private itemID = ""
    private itemClicked
    private articleData: ArticleData | undefined

    constructor(private pconFactsService: PconFactsService, private folderService: BasketFolderService, private eaiwsService: EaiwsService) {
        this.itemClicked = this.folderService.itemClickedEmitter.subscribe((itemID) => this.changedSelectedItem(itemID))
        this.changedSelectedItem(this.folderService.currentItemID)
    }
    async load() {
        if (!this.articleData) return

        const cats = (await this.pconFactsService.loadCategories(this.articleData)).categories
        this.categories = cats.map((val => {
            return {
                category: val.category,
                name: val.name,
                contentTypes: val.contentTypes,
                assets: [],
                open: false
            } as CategoryWrapperCustom
        }))
    }

    ngOnInit(): void {

    }
    ngOnDestroy(): void {

    }


    async changedSelectedItem(itemID: string) {
        this.itemID = itemID
        this.articleData = await this.eaiwsService.eaiwsSession.basket.getArticleData(this.itemID)

        this.load()
    }


    async onClickOpenCategory(cat: CategoryWrapperCustom) {
        if (!this.articleData) return

        if (cat.assets.length == 0) {
            cat.assets = (await this.pconFactsService.loadAssets(cat, this.articleData)).results
        }
        const open = cat.open

        this.categories.forEach(cat => cat.open = false)
        if (!open) {
            cat.open = true
        }
    }

    getContactData(asset: Result) {
        if (!asset.data) return
        if (typeof asset.data == "string") return

        const data = <ContactData>asset.data
        return data.name + "<br>" + data.phone + "<br>" + data.email + "<br>" + data.web
    }
}

<div class="pconFacts">
    <div class="categories">
        <div *ngFor="let cat of categories" class="category" (click)="onClickOpenCategory(cat)">
            <span class="title">{{ "pconFactsCategories." + cat.category | translate}}</span>
            <div class="assets" [class.open]="cat.open">
                <div *ngFor="let asset of cat.assets" class="asset">
                    <a *ngIf="asset.category != 'CONTACT'" [href]="asset.uri" target="_blank">
                        <img [src]="asset.thumbnailUri" [alt]="asset.name" />
                    </a>
                    <div *ngIf="asset.category == 'CONTACT'" class="contact">
                        <span [innerHTML]="getContactData(asset)"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Language, LanguageService } from 'src/app/services/language.service';

@Component({
    selector: 'app-language-switch',
    templateUrl: './language-switch.component.html',
    styleUrls: ['./language-switch.component.scss']
})
export class LanguageSwitchComponent implements OnInit, OnDestroy {
    public languages: Language[] = []
    public currentLang
    public open = false
    public height = 0

    private languageChangedEvent

    readonly languageHeight = 40

    constructor(private languageService: LanguageService, private router: Router) {
        this.languages = languageService.getAvailableLanguages()
        this.languageChangedEvent = languageService.changedLanguage.subscribe(lang => {
            this.currentLang = lang
        })
        this.currentLang = this.languageService.getCurrentLanguage()
    }
    ngOnInit(): void {

    }
    ngOnDestroy(): void {
        this.languageChangedEvent.unsubscribe()
    }


    onClickCurrentLanguage() {
        this.open = !this.open
        this.height = this.open ? this.languageHeight * (this.languages.length - 1) : 0
    }

    onClickLanguage(lang: Language) {
        const newRoute = this.router.url.split("/").slice(2)
        newRoute.unshift(lang.id)

        this.router.navigate(newRoute)
        this.onClickCurrentLanguage()
    }
}

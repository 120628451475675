export interface CategoryQuery {
    /**
     * This section specifies the article for which the categories should be returned. All relevant OFML fields of the article are provided. Which fields are actually used is up to the provider of the product information.
     */
    article: Article;

    /**
     * This section contains filters that the target application may set to display only product information relevant in the respective use case. Only categories that contain at least one product information item that fulfills ALL filters should be returned.
     */
    filters: Filters;

    /**
     * This section contains information about the requesting user. This makes it possible to provide different product information for certain user groups. Users with a catalog approval may be provided with full product information and public users only with limited product information. Country and zip code of the user allow to provide different contact data depending on the location of the user.
     */
    user?: User;
}

export interface CategoryQueryResponse {
    categories: CategoryWrapper[];
}

export interface ProductQuery {
    /**
     * This section specifies the article for which the categories should be returned. All relevant OFML fields of the article are provided. Which fields are actually used is up to the provider of the product information.
     */
    article: Article;

    /**
     * This section contains filters that the target application may set to display only product information relevant in the respective use case. Only categories that contain at least one product information item that fulfills ALL filters should be returned.
     */
    filters: Filters;

    /**
     * This section contains information about the requesting user. This makes it possible to provide different product information for certain user groups. Users with a catalog approval may be provided with full product information and public users only with limited product information. Country and zip code of the user allow to provide different contact data depending on the location of the user.
     */
    user: User;

    /**
     * This section specifies the range of results that should be returned. Consecutive calls for the same article and the same filter values, but with adjusted values of resultRange must produce a coherent list of results. The target application sets this values depending on the requirements of the user interface. It should define this values carefully to achieve both optimal load time for the user and reasonable server load for the provider.
     */
    resultRange: ResultRange;
}

export interface ProductQueryResponse {
    resultInfo: ResultInfo;
    results: Result[];
}


export interface ResultInfo {
    /**
     * The total number of available results for the given request, considering the filters, but disregarding the specified result range of the request. For example: 34 results are available for given request considering the given filters. The caller requests 10 results starting at 0. Then, for total, 34 should be returned. With this information, the caller can, for example, display the number of result pages or (de-)activate a button "Continue" for further search results. If the value can only be determined with considerable effort, it can be omitted.
     */
    total?: number;
}

export interface Result {
    /**
     * Category of the respective product information (See page 7)
     */
    category: Category;

    /**
     * Media type of the product information (See page 8)
     */
    contentType: MediaTypes;

    /**
     * Name of product information. If this field is not provided, the file name will be used. Please note: This is not the file name, but the name under which the document is displayed in the pCon application. Examples: “Home Office Furnishing Solutions”, “Color overview” If you want to provide a file name under which the document will be saved during a download, please use the respective part of the URI or the HTTP header Content-Disposition.
     */
    name?: string;

    /**
     * Thumbnail of the product information, usable for all media types; The preview image must be an image in one of the formats PNG, JPEG or GIF and must be reachable via https.
     */
    thumbnailUri?: string;

    /**
     * URI of the reference web page or source web page for the product information
     */
    referrerUri?: string;

    /**
     * Language of the provided information The same language as the requested article should be used. If this is not possible, information in an alternative language may be provided.
     */
    language?: string;

    /**
     * Size of the actual product information in Bytes (The value may be approximate. An exact value may be obtained by an http HEAD request on the resource.)
     */
    size?: number;

    /**
     * Width of the images (only for images) The value is required to achieve an optimal display of the image in the target application.
     */
    imageWidth?: number;

    /**
     * Height of the images (only for images) The value is required to achieve an optimal display of the image in the target application.
     */
    imageHeight?: number;

    /**
     * URI under which the product information is available as a file - One of the two fields uri and data is obligatory. The use of both fields at the same time is not allowed.
     */
    uri?: string;

    /**
     * The product information (embedded, only for text information). One of the two fields uri or data is obligatory. The use of both fields at the same time is not allowed. Please remember that the product information is often used on smartphones and should therefore not be too long. The size of the embedded information must not exceed 10 KiB per entry, otherwise external files must be used. Some characters (for example line breaks) must be replaced with escape sequences according to the JSON standard. Text data must be provided in the character set UTF-8.
     */
    data?: string | ContactData | DesignerData;
}

export interface ContactData {
    /**
     * Name of the contact person/team
     */
    name: string;

    /**
     * Photo of the contact person/team
     */
    photo?: Photo,

    /**
     * Department; serves to differentiate the responsibility for several contacts, e. g. sales, customer service, spare parts. The value is language dependent. We recommend providing it in the language stated in the language attribute of the query.
     */
    department?: string;

    /**
     * E-mail address of the contact person/team
     */
    email: string;

    /**
     * Phone number in E.123 international notation, e.g. +22 607 123 4567 (Parentheses are not allowed, a national trunk prefix (often “0”) has to be omitted.)

     */
    phone: string;

    /**
     * Website URL
     */
    web: string;
}

export interface DesignerData {
    /**
     * Name of the contact person/team
     */
    name: string;

    /**
     * Photo of the contact person/team
     */
    photo: Photo,

    /**
     * Further information about the designer(s), e.g. their specialties, motivations, intentions. The text is language dependent. We recommend providing it in the language stated in the language attribute of the query.
     */
    text: Text
}

export interface Text {
    /**
     * Media type of the text (See page 8) Only text/plain and text/markdown are supported.
     */
    contentType: MediaTypes.markdown | MediaTypes.plain;

    /**
     * The actual text. The text should not exceed 200 words.
     */
    data: string;
}

export interface Photo {
    /**
     * URI of the photo
     */
    uri: string;

    /**
     * Type of the photo.
     */
    type: PhotoType;
}


export interface Article {
    /**
     * Commercial identifier of the manufacturer (The manufacturer’s commercial short name which was used to register the manufacturer with EasternGraphics. It always consists of uppercase letters. It is also in the DSR key manufacturer.)
     */
    manufacturerId: string;

    /**
     * Display name of the manufacturer
     */
    manufacturerName?: string;

    /**
     * OFML identifier of the manufacturer (This is the manufacturer’s OFML short name which was used to register the manufacturer with EasternGraphics. It always consists of lowercase letters. It is also in the DSR key manufacturer.)
     */
    manufacturer: Manufacturer;

    /**
     * OFML identifier of the series (This is in the DSR key program, consisting lowercase letters)
     */
    program?: string;

    /**
     * Commercial identifier of the series (This is in the DSR key program_id, consisting uppercase letters)
     */
    programId?: string;

    /**
     * Display name of the series
     */
    programName?: string;

    /**
     * Identifier of the sales area (This is in the DSR key distribution_region.) If provided, it may be used to provide more specific results.
     */
    distributionRegion?: string;

    /**
     * Path in the catalog If provided, it may be used to provide more specific results.
     */
    catalogPath?: string;

    /**
     * (Base) article number. Kann Artikelnummern Kommagetrennt angegeben werden.
     */
    artNo: string;

    /**
     * Final article number
     */
    finalArtNo?: string;

    /**
     * Variant code
     */
    varCode?: string;

    /**
     * Long text of the article
     */
    longText?: string;

    /**
     * Short text of the article
     */
    shortText?: string;

    /**
     * Language of identifiers/texts in the request, if language-dependent
     */
    language: string;
}


export interface Filters {
    /**
     * Filter for the categories. Must be an array of categories (page 5). Only product information from these categories should be returned. If the array contains the value * or the field is missing, then all categories should be considered.
     */
    categories?: Category[];

    /**
     * Filter for the media types. Only product information of these media types should be considered. If the array contains the value * or the field is missing, then all media types should be considered.
     */
    contentTypes?: MediaTypes[];

    /**
     * Filter for the width of images. Only Images with a width ≥ minWidth should be considered. This filter should only be considered for images.
     */
    imageMinWidth?: number;

    /**
     * Filter for the height of images. Only Images with a height ≥ minHeight should be considered. This filter should only be considered for images.
     */
    imageMinHeight?: number;
}



export interface User {
    /**
     * Type of the user.
     */
    type: UserType;

    /**
     * Zip code of the requesting user. Not available for public users. The value is taken from the user’s pCon.login user account and its correctness cannot be guaranteed.
     */
    zipcode?: string;

    /**
     * ISO 3166-1 alpha -2 Country of the user, e.g. DE. Not available for public users. The value is taken from the user’s pCon.login user account and its correctness cannot be guaranteed.
     */
    country?: string;
}

export interface ResultRange {
    /**
     * Position of the result to be returned first. The first result has the position 0. If the given value is greater than the total number of results, an empty list has to be returned.
     */
    start: number;

    /**
     * Number of results to be returned at most. The last result is reached if fewer results are returned. Not more than 100 results will be requested in one call. If more than 100 results are requested, the provider is entitled to return error 400 (Bad Request).
     */
    count: number;
}

export interface CategoryWrapper {
    /**
     * Contains the identifier of the provided category (page 4) Both predefined categories and custom categories are possible. Please make sure that each category only occurs once within the whole response.
     */
    category: Category;

    /**
     * Only for own categories: Display name of the category in the same language as the requested article. The field is not required for the predefined categories.
     */
    name?: string;

    /**
     * Must be an array of media types (see page 8) for which product information in that category are provided
     */
    contentTypes: MediaTypes[];
}

export interface CategoryWrapperCustom extends CategoryWrapper {
    assets: Result[]
    open: boolean
}


export enum UserType {
    /**
     * Registered pCon user who has manufacturer approval to use the respective OFML catalog
     */
    hasCatalogApproval = "hasCatalogApproval",

    /**
     * Unregistered user without a catalog approval.
     */
    public = "public"
}

export enum MediaTypes {
    /**
     * Simple text (UTF-8)
     */
    plain = "text/plain",

    /**
     * Markdown text (UTF-8)
     */
    markdown = "text/markdown",

    /**
     * Image (JPEG)
     */
    jpeg = "image/jpeg",

    /**
     * Image (PNG)
     */
    png = "image/png",

    /**
     * Image (GIF)
     */
    gif = "image/gif",

    /**
     * PDF
     */
    pdf = "application/pdf",

    /**
     * Structured information (JSON) Only permitted in the cases described in this document, e.g. contact data.
     */
    json = "application/json",

    /**
     * Other files This type can be used to provide other files (e.g. photometric data, BIM data). These files are not processed by the pCon applications, but passed on to the operating system for further handling. Please note that the files should be cross-platform (Windows, MacOS, iOS, Android). The provision of files with executable content (e.g. EXE, BAT, DOC, DOCX, XLS, XLSX, PPT, PPTX) is not permitted for security reasons. We reserve the right to disable interfaces with such files.
     */
    octetStream = "application/octet-stream",

    /**
     * Currently only videos hosted on YouTube are supported.
     */
    youtube = "video/x-egr-youtube",

    /**
     * All MediaTypes
     */
    all = "*"
}



export enum Category {
    /**
     * Product brochure PDF
     */
    PRODUCT_BROCHURE = "PRODUCT_BROCHURE",

    /**
     * General product information Text, PDF
     */
    PRODUCT_INFORMATION = "PRODUCT_INFORMATION",

    /**
     * Product image Image
     */
    PRODUCT_IMAGE = "PRODUCT_IMAGE",

    /**
    * Image of a furnishing solution containing the product Image
    */
    SOLUTION_IMAGE = "SOLUTION_IMAGE",

    /**
     * Product video Video
     */
    PRODUCT_VIDEO = "PRODUCT_VIDEO",

    /**
    * Designer (only as JSON document with a specific structure) JSON
    */
    DESIGNER = "DESIGNER",

    /**
     * Contact information (only as JSON document with a specific structure) JSON
     */
    CONTACT = "CONTACT",

    /**
    * Specifications, tender specifications PDF
    */
    SPECIFICATIONS = "SPECIFICATIONS",

    /**
     * Certificate, e.g. ISO 9001, TÜV GS, FSC Image, PDF with image as thumbnail, Text
     */
    CERTIFICATE = "CERTIFICATE",

    /**
    * Environmental information Text, PDF, Video
    */
    ENVIRONMENTAL_INFORMATION = "ENVIRONMENTAL_INFORMATION",

    /**
     * Material images, material documents, material description Image, PDF, Text
     */
    MATERIAL_INFORMATION = "MATERIAL_INFORMATION",

    /**
    * Instructions for use PDF, Video
    */
    USER_INSTRUCTIONS = "USER_INSTRUCTIONS",

    /**
     * Care instructions PDF, Video
     */
    CARE_INSTRUCTIONS = "CARE_INSTRUCTIONS",

    /**
    * Operating instructions PDF, Video
    */
    OPERATING_INSTRUCTIONS = "OPERATING_INSTRUCTIONS",

    /**
     * Assembly instructions PDF, Video
     */
    ASSEMBLY_INSTRUCTIONS = "ASSEMBLY_INSTRUCTIONS",

    /**
     * CAD ZIP Daten
     */
    CUSTOM_CAD = "CUSTOM_CAD",

    /**
     * Wildcard that matches all categories. May be used to search in all categories.
     */
    All = "*",
}


export enum PhotoType {
    /**
     * Portrait photo, may be shown rounded in some applications
     */
    portrait = "portrait",

    /**
     * Other photo, will be shown as is in all applications
     */
    other = "other"
}


export enum Manufacturer {
    KLOEBER = "klx",
    SEDUS = "sex",
    SYSTEMS = "gsx",
    SEDUSNETTO = "sen",
}

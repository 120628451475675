import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PageW3codeBase } from '../page-w3code-base';
import { EaiwsOptions } from 'src/app/models/options';
import { environment } from 'src/environments/environment';
import { Manufacturer } from 'src/app/models/manufacturer';

@Component({
    selector: 'app-page-interactive-ab',
    templateUrl: './page-interactive-ab.component.html',
    styleUrls: ['./page-interactive-ab.component.scss']
})
export class PageInteractiveAbComponent extends PageW3codeBase implements OnInit {

    options: EaiwsOptions = {
        showPrice: false,
        showPriceOptions: true,
        showDiscountPrice: false,
        showConfiguration: false,
        showCallToAcion: false,
        canConfigured: false,
        canArticleDelete: false,
        showCatalog: false,
        backend: environment.backendBaseUrlSedus,
        manufacturer: Manufacturer.sedus,
        showBreadcrump: true,
        allowedSerie: ["SY"],
        orderNumber: ""
    }

    constructor(private injector: Injector) {
        super(injector)

        this.activatedRoute.params.subscribe(paras => {
            this.options.orderNumber = paras["orderNumber"]
        })
    }

    ngOnInit(): void {

    }

}

import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ArticleProperties, ItemProperties } from '@egr/wcf/modules/eaiws/basket';
import { EaiwsOptions } from 'src/app/models/options';
import { BasketFolderService } from 'src/app/services/basket-folder.service';
import { EaiwsService } from 'src/app/services/eaiws.service';
import { LanguageService } from 'src/app/services/language.service';
import { PropertyService } from 'src/app/services/property.service';

@Component({
    selector: 'app-additionally-ui',
    templateUrl: './additionally-ui.component.html',
    styleUrls: ['./additionally-ui.component.scss']
})
export class AdditionallyUiComponent implements OnInit, OnDestroy {

    @Input() options: EaiwsOptions = {
        showPriceOptions: false,
        showDiscountPrice: false,
        showPrice: true,
        showSALText: false,
        restrictConfiguration: false,
        allowedConfiguration: [],
        canConfigured: false
    }

    itemID = ""
    articlePrice = 0
    articleDiscount: number | undefined = undefined
    articleAmount: number | undefined = undefined
    articleSAL: string = ""
    articleDiscountedPrice = 0

    private languageChanedEvent
    private clickedChoiceEvent
    private itemClickedEvent

    constructor(public eaiwsService: EaiwsService, public folderService: BasketFolderService, private propertyService: PropertyService, private languageService: LanguageService) {
        this.itemClickedEvent = this.folderService.itemClickedEmitter.subscribe((itemID) => {
            this.itemID = itemID
            this.loadArticlePoperties()
        })

        this.languageChanedEvent = languageService.changedLanguage.subscribe(lang => {
            this.loadArticlePoperties()
        })

        this.clickedChoiceEvent = propertyService.clickedChoice.subscribe(propValue => {
            this.loadArticlePoperties()
        })

        this.itemID = this.folderService.currentItemID
        this.loadArticlePoperties()
    }
    ngOnInit(): void {

    }
    ngOnDestroy(): void {
        this.languageChanedEvent.unsubscribe()
        this.clickedChoiceEvent.unsubscribe()
        this.itemClickedEvent.unsubscribe()
    }



    async loadArticlePoperties() {
        const [priceSheet, texts] = await Promise.all([
            this.eaiwsService.eaiwsSession.basket.getPriceCalculationSheet([this.itemID], "STDB2B_SEDUS_2"),
            this.eaiwsService.eaiwsSession.basket.tmGetText(null, this.itemID, true, true)
        ])

        if (!priceSheet.quantity) return

        const sellPrice = priceSheet.lines.find(line => line.level == 125)
        const nettorabatt = priceSheet.lines.find(line => line.conditionType == "SDIP02")

        this.articleAmount = priceSheet.quantity.value ? priceSheet.quantity.value : 0
        this.articlePrice = sellPrice?.value?.value ? sellPrice?.value?.value : 0
        this.articleDiscount = nettorabatt?.amount?.value ? nettorabatt.amount.value * -1 : 0
        this.articleDiscountedPrice = priceSheet.netValue?.value ? priceSheet.netValue?.value : 0

        const salText = texts.find(text => text.textId == "71")?.texts[0]
        this.articleSAL = salText ? salText.text : ""

        this.propertyService.priceChanged.next(true)
    }

    async onChangeDiscount(value: number) {
        await this.eaiwsService.eaiwsSession.basket.adjustCalculationLineAmount([this.itemID], "STDB2B_SEDUS_2", 410, 2, value * -1, "%")
        void this.loadArticlePoperties()
    }

    async onChangeAmount(value: number) {
        const article = new ArticleProperties()
        article.quantity = value

        const prop = new ItemProperties()
        prop.article = article

        await this.eaiwsService.eaiwsSession.basket.setItemProperties(this.itemID, prop)
        void this.loadArticlePoperties()
    }

    onChangeSAL(value: string) {
        this.eaiwsService.eaiwsSession.basket.tmSetText(null, this.itemID, "71", "de", value)
    }

    canConfigured() {
        if (!this.options.canConfigured) return false

        return this.options.canConfigured
    }
}

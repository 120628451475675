import { BasketService, OperationMode } from "@egr/wcf/modules/eaiws/basket"

export class DownloadAction {

    constructor(public text: string, public type: DownloadType, protected basketService: BasketService, protected itemID: string) {

    }

    async do() {
        const url = await this.basketService.getExportedGeometry(this.itemID, ["format=" + this.type])
        if (url == "") return console.log("Geometry konnte nicht erstellt werden")

        void this.download(url)
    }

    protected download(url: string, name: string = "") {
        const link = document.createElement("a")
        link.download = name
        link.href = url
        link.click()
    }

    protected toDataURL(url: string) {
        return fetch(url).then((response) => {
            return response.blob();
        }).then(blob => {
            return URL.createObjectURL(blob);
        });
    }

}

export class DownloadActionImage extends DownloadAction {

    override async do() {
        const url = await this.basketService.getGeneratedImage(this.itemID, [
            "width=1600",
            "height=2000",
            "alpha=-25", //kleiner nach rechts
            "beta=10", //größer nach oben
            "projectionMode=Perspective",
            "format=JPG",
            "shadowPlane=true",
            "shadowPlane.filter=DOF",
            "shadowPlane.color=0.4 0.4 0.4",
            "ambient=0.8 0.8 0.8",
            "renderMode=PBR",
            "zoom=1"
        ])
        if (url == "") return console.log("Bild konnte nicht erstellt werden")

        void this.download(await this.toDataURL(url))
    }
}

export class DownloadActionOBX extends DownloadAction {

    constructor(text: string, type: DownloadType, basketService: BasketService) {
        super(text, type, basketService, "")
    }

    override async do() {
        const itemIds = await this.basketService.getAllItems()
        const mode = new OperationMode()
        mode.mainArticles = true
        mode.subPositions = true

        const url = await this.basketService.copy(itemIds.map(item => item.itemId), mode)

        if (url == "") return console.log("OBX konnte nicht erstellt werden")

        void this.download(await this.toDataURL(url), "Angebot.obx")
    }
}

export enum DownloadType {
    "DS3" = "3DS",
    "DWG" = "DWG",
    "GFX" = "GFX",
    "SKP" = "SKP",
    "FBX" = "FBX",
    "OBJ" = "OBJ",
    "DAE" = "DAE",
    "IGXC" = "IGXC",
    "GFJ" = "GFJ",
    "GLTF" = "GLTF",
    "USD" = "USD",
    "OBX" = "OBX",
    "Image" = "Image"
}

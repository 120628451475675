
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { EaiwsService } from './eaiws.service';
import { ProjectData } from '@egr/wcf/modules/eaiws/project';
import { UpdateBasketArticlesOptions } from '@egr/wcf/modules/eaiws/basket';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {
    changedLanguage = new Subject<Language>()

    private currentLanguage: Language = { id: "de", name: "DE", local: "de-DE" }

    constructor(private translateService: TranslateService, private eaiwsService: EaiwsService) {

    }

    async setLanguage(lang: Language | string) {
        let newLang: Language

        if (typeof lang === "string") {
            const foundLang = this.getLanguage(lang)
            newLang = foundLang ? foundLang : this.getLanguage("de")!
        } else {
            newLang = lang
        }

        this.currentLanguage = newLang
        await this.updateEaiwsLanguage(newLang)
        this.translateService.use(newLang.id)

        this.changedLanguage.next(newLang)
    }

    getCurrentLanguage() {
        return this.currentLanguage
    }
    getLanguage(langId: string) {
        return this.getAvailableLanguages().find(allLang => allLang.id == langId)
    }

    getAvailableLanguages(): Language[] {
        return [
            { id: "de", name: "DE", local: "de_DE" },
            { id: "en", name: "EN", local: "en_GB" },
            { id: "fr", name: "FR", local: "fr_FR" },
            { id: "nl", name: "NL", local: "nl_NL" },
        ]
    }


    public async updateEaiwsLanguage(lang: Language | undefined = undefined) {
        if (!this.eaiwsService.eaiwsSession.catalog || !this.eaiwsService.eaiwsSession.project || !this.eaiwsService.eaiwsSession.session || !this.eaiwsService.eaiwsSession.basket) return

        const newLang = lang ? lang : this.currentLanguage
        const projectData = new ProjectData()
        projectData.languages = [newLang.id]

        const changeLang: Promise<void>[] = [
            this.eaiwsService.eaiwsSession.catalog.setLanguages([newLang.id, "en"]),
            this.eaiwsService.eaiwsSession.project.setProjectData(projectData),
            this.eaiwsService.eaiwsSession.session.setLocale(newLang.local),
            this.eaiwsService.eaiwsSession.basket.setLanguages([newLang.id, "en"])
        ]

        await Promise.all(changeLang)
        const updateOptions = new UpdateBasketArticlesOptions()
        updateOptions.update = true
        updateOptions.migrate = true
        updateOptions.parentItems = true
        updateOptions.priceDate = moment().format("YYYY-MM-DD")
        updateOptions.recalculate = true
        updateOptions.subItems = true
        updateOptions.wholeComposite = true

        const items = await this.eaiwsService.eaiwsSession.basket.getAllItems()
        //await this.eaiwsService.eaiwsSession.basket.updateBasketArticles(items.map(item => item.itemId), null, updateOptions)
    }

}


export interface Language {
    id: string
    name: string
    local: string
}

import { Injectable } from '@angular/core';
import { ArticleCatalogItem, CatalogItem } from '@egr/wcf/modules/eaiws/catalog';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CatalogService {
    newArticle = new Subject<ArticleCatalogItem>()
    clickListCatalogItem = new Subject<CatalogItem>()
}

import { Injectable } from '@angular/core';
import * as core from '@egr/wcf/modules/core';
import * as tool from '@egr/wcf/modules/core/tool';
import { ShadowMapping, ShadowPlane } from '@egr/wcf/modules/core/rendering';
import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import { CameraControl } from '@egr/wcf/modules/core/view/CameraControl';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ViewerService {
    showLoading = new Subject<boolean>()
    eventDoResizeViewer = new Subject<boolean>()
    coreApp: core.Application | undefined

    constructor() {

    }

    init(coreApp: core.Application) {
        this.coreApp = coreApp

        const cameraControl = this.coreApp.viewer.view.cameraControl;
        cameraControl.setFixedTarget(new Vector3(0, 0.3, 0));
        cameraControl.dblClickZoomToFitOptions.adjustFixedTarget = false;
        cameraControl.orbitInertia = 0.95;
        this.setMovingAllowed(false)
        this.setZoomAllowed(false)


        const defaultTool: tool.SelectionDefault = new tool.SelectionDefault(this.coreApp);
        defaultTool.deselectionEnabled = false;
        defaultTool.showMainElementSelection = false;
        defaultTool.showElementInteractors = true;
        this.coreApp.tools.defaultTool = defaultTool;
        this.coreApp.tools.startDefaultTool();


        this.coreApp.rendering.addShadowGenerator(new ShadowPlane(this.coreApp));
        this.coreApp.rendering.addShadowGenerator(new ShadowMapping(this.coreApp));
    }


    /**
    * Should by called after inserting new elements.
    */
    public resetCamera(): boolean {
        if (!this.coreApp) return false

        this.coreApp.viewer.view.cameraControl.setFixedTarget(this.getCenterOfSceneElement());

        const radiusMultiplier = 3; // should be changed to value you want
        const maxZoomRadius: number = this.getRadiusOfSceneElement() * radiusMultiplier; // maxZoomRadius can also be a fixed value, but we take size of element into count
        this.coreApp.viewer.view.cameraControl.setNavigationArea(maxZoomRadius, this.getCenterOfSceneElement());

        this.coreApp.viewer.view.cameraControl.setPosition(CameraControl.DEFAULT_CAMERA_POSITION);
        this.coreApp.viewer.view.cameraControl.zoomToFitElements(null);
        this.coreApp.viewer.requestRenderFrame();

        return true
    }

    private getCenterOfSceneElement(): Vector3 {
        if (!this.coreApp) return Vector3.Zero();

        if (this.coreApp.model.elements.length > 0 && this.coreApp.model.elements[0].boundingBox.isValid()) {
            return this.coreApp.model.elements[0].boundingBox.getCenter();
        }
        return Vector3.Zero();
    }

    private getRadiusOfSceneElement(): number {
        if (!this.coreApp) return 20

        if (this.coreApp.model.elements.length > 0 && this.coreApp.model.elements[0].boundingBox.isValid()) {
            return this.coreApp.model.elements[0].boundingBox.getRadius();
        }
        return 20; // 20 meter, if nothing is in the scene
    }



    /**
     * If we have multiple articles in the scene (i.e. by loading a .pec), then we need to show the user which one is currently selected by a bounding box.
     */
    public allowMainArticleSelection(value: boolean): boolean {
        if (!this.coreApp) return false

        if (this.coreApp.tools.defaultTool instanceof tool.SelectionDefault) {
            this.coreApp.tools.defaultTool.showMainElementSelection = value;
            this.coreApp.tools.defaultTool.deselectionEnabled = value;
        }

        return true
    }


    setZoomAllowed(value: boolean) {
        if (!this.coreApp) return

        this.coreApp.viewer.view.cameraControl.zoomEnabled = value
    }

    resizeView() {
        this.eventDoResizeViewer.next(true)
    }

    setMovingAllowed(value: boolean) {
        if (!this.coreApp) return

        this.coreApp.viewer.view.cameraControl.panningEnabled = value;
    }
    isMovingAllowed() {
        if (!this.coreApp) return false

        return this.coreApp.viewer.view.cameraControl.panningEnabled;
    }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BasketFolderService {
    itemClickedEmitter = new Subject<string>()
    itemDeleteEmitter = new Subject<string>()
    callToActionEmitter = new Subject<string>()

    currentItemID = ""

    constructor() {
        this.itemClickedEmitter.subscribe(itemID => {
            this.currentItemID = itemID
        })
    }
}

<div [className]="getClassName()">
    <div *ngIf="!firstFolder" class="parent">
        <span class="title" (click)="onClickFolder()" [style.paddingLeft]="(level - 1) * 20 + 'px'">
            <i class="icon-folder-open" *ngIf="isOpen"></i>
            <i class="icon-folder" *ngIf="!isOpen"></i>
            {{currentFolder?.current?.label}} <span *ngIf="options.showPrice" class="price">- {{currentFolder?.price | currency:'€':'symbol':'1.2-2':'de-DE'}}</span>
            <i *ngIf="options.canArticleDelete && currentFolder && currentFolder.current.itemId" (click)="onClickDeleteFolder()" class="icon-cancel"></i>
        </span>
    </div>
    <div class="items" *ngIf="isOpen">
        <app-basket-buttons *ngIf="options.designClass == 'kloeber'" [options]="options"></app-basket-buttons>
        <h2 *ngIf="options.designClass == 'kloeber'">{{"title.article" | translate}}</h2>
        <div class="item" *ngFor="let item of currentFolder?.items">
            <span class="title" [class.active]="item.current.itemId == selectedItemID" [style.paddingLeft]="level * 20 + 'px'" *ngIf="item.current?.itemType != 'Folder'" (click)="onClickItem(item.current.itemId)">
                <i class="icon-cube"></i>{{item.current.label}}<i *ngIf="options.canArticleDelete" (click)="onClickDeleteArticle(item.current.itemId)" class="icon-cancel"></i>
            </span>
            <app-basket-folder [level]="level + 1" *ngIf="item.current?.itemType == 'Folder'" [folder]="item" [options]="options"></app-basket-folder>
        </div>
    </div>
</div>
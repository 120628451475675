import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageInteractiveAbComponent } from './pages/page-interactive-ab/page-interactive-ab.component';
import { PageKloeberConfiguratorComponent } from './pages/page-kloeber-configurator/page-kloeber-configurator.component';
import { PageKloeberMesseComponent } from './pages/page-kloeber-messe/page-kloeber-messe.component';
import { PageLanguageComponent } from './pages/page-language/page-language.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { PageSedusConfiguratorComponent } from './pages/page-sedus-configurator/page-sedus-configurator.component';

const routes: Routes = [{
    path: ":language",
    component: PageLanguageComponent,

    children: [
        {
            component: PageInteractiveAbComponent,
            path: "order/:orderNumber"
        }, {
            component: PageKloeberConfiguratorComponent,
            path: "configurator/klx"
        }, {
            component: PageKloeberConfiguratorComponent,
            path: "configurator/klx/:productFamily"
        }, {
            component: PageKloeberConfiguratorComponent,
            path: "configurator/klx/:productFamily/:product"
        }, {
            component: PageSedusConfiguratorComponent,
            path: "configurator/sex"
        }, {
            component: PageSedusConfiguratorComponent,
            path: "configurator/sex/obx/:obxName"
        }, {
            component: PageSedusConfiguratorComponent,
            path: "configurator/sex/:productFamily"
        }, {
            component: PageSedusConfiguratorComponent,
            path: "configurator/sex/:productFamily/:product"
        }, {
            component: PageKloeberMesseComponent,
            path: "messe/klx"
        }, {
            component: PageNotFoundComponent,
            path: '**',
            pathMatch: 'full'
        }
    ]
}, {
    component: PageNotFoundComponent,
    path: '**',
    pathMatch: 'full'
}];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }


export interface ParasLanguage {
    language: string
}

export interface ParasConfigurator extends ParasLanguage {
    obxName?: string
    productFamily?: string,
    product?: string
}

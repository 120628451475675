export class Manufacturer {

    public static kloeber = new Manufacturer("KL", "klx", "KLOEBER_DE")
    public static sedus = new Manufacturer("SE", "sex", "SEDUS_DE_EUR")
    public static systems = new Manufacturer("SE", "gsx", "SEDUS_DE_EUR")

    constructor(public manufacturer: string, public ofmlName: string, public startUp: string) {

    }
}

<div class="content">
    <app-catalog-ui-list *ngIf="options.showCatalog" [options]="options"></app-catalog-ui-list>
    <app-catalog-ui *ngIf="options.showCatalog" [options]="options"></app-catalog-ui>

    <div class="outerViewer">
        <app-basket-ui class="basketUI" [items]="items" [options]="options"></app-basket-ui>

        <as-split class="split" *ngIf="!isMobile700" unit="percent" gutterAriaLabel="adjustable divider between two views" #split="asSplit" (dragEnd)="onDragEnd()">
            <as-split-area [size]="30" #area3="asSplitArea" id="detailsUI">
                <div *ngIf="clickedItemID == ''" class="loading">
                    {{"message.selectItem" | translate}}
                </div>

                <mat-tab-group *ngIf="clickedItemID != ''" animationDuration="0">
                    <mat-tab [label]="'title.configuration' | translate">
                        <app-property-ui [options]="options"></app-property-ui>
                    </mat-tab>
                    <mat-tab [label]="'title.articleAdditionally' | translate">
                        <app-additionally-ui [options]="options"></app-additionally-ui>
                    </mat-tab>
                    <mat-tab [label]="'title.documents' | translate">
                        <app-pcon-facts-ui [options]="options"></app-pcon-facts-ui>
                    </mat-tab>
                </mat-tab-group>
            </as-split-area>
            <as-split-area [size]="70" #area2="asSplitArea" id="viewerUI">
                <app-viewer-ui #viewerUI [options]="options"></app-viewer-ui>
            </as-split-area>
        </as-split>

        <div #mobile *ngIf="isMobile700" class="mobile">
            <mat-tab-group *ngIf="clickedItemID != ''" animationDuration="0">
                <mat-tab [label]="'title.configuration' | translate">
                    <app-property-ui [options]="options"></app-property-ui>
                </mat-tab>
                <mat-tab [label]="'title.articleAdditionally' | translate">
                    <app-additionally-ui [options]="options"></app-additionally-ui>
                </mat-tab>
                <mat-tab [label]="'title.documents' | translate">
                    <app-pcon-facts-ui [options]="options"></app-pcon-facts-ui>
                </mat-tab>
            </mat-tab-group>
            <div class="viewerWrapper">
                <app-viewer-ui #viewerUI [options]="options"></app-viewer-ui>
            </div>
        </div>
    </div>
</div>
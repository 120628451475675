import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeNl from '@angular/common/locales/nl';
import localeNlExtra from '@angular/common/locales/extra/nl';
import localeEs from '@angular/common/locales/es';
import localeEsExtra from '@angular/common/locales/extra/es';
import localeIt from '@angular/common/locales/it';
import localeItExtra from '@angular/common/locales/extra/it';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BasketFolderComponent } from './components/basket/basket-folder/basket-folder.component';
import { BasketUIComponent } from './components/basket/basket-ui/basket-ui.component';
import { AngularSplitModule } from 'angular-split';
import { PropertyUIComponent } from './components/property/property-ui/property-ui.component';
import { PageInteractiveAbComponent } from './pages/page-interactive-ab/page-interactive-ab.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { PageKloeberConfiguratorComponent } from './pages/page-kloeber-configurator/page-kloeber-configurator.component';
import { CatalogUIComponent } from './components/catalog/catalog-ui/catalog-ui.component';
import { PropertySingleComponent } from './components/property/property-single/property-single.component';
import { ViewerUIComponent } from './components/viewer/viewer-ui/viewer-ui.component';
import { registerLocaleData } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatTabsModule } from "@angular/material/tabs";
import { MatSelectModule } from "@angular/material/select";
import { FormsModule } from '@angular/forms';
import { PageLanguageComponent } from './pages/page-language/page-language.component';
import { FormKloeberComponent } from './components/form/form-kloeber/form-kloeber.component';
import { PageKloeberMesseComponent } from './pages/page-kloeber-messe/page-kloeber-messe.component';
import { MesseUiComponent } from './components/catalog/messe-ui/messe-ui.component';
import { LanguageSwitchComponent } from './components/other/language-switch/language-switch.component';
import { PageSedusConfiguratorComponent } from './pages/page-sedus-configurator/page-sedus-configurator.component';
import { PconFactsUiComponent } from './components/pconFacts/pcon-facts-ui/pcon-facts-ui.component';
import { AdditionallyUiComponent } from './components/property/additionally-ui/additionally-ui.component';
import { EaiwsBaseLayoutComponent } from './components/eaiws/eaiws-base-layout/eaiws-base-layout.component';
import { EaiwsKloeberLayoutComponent } from './components/eaiws/eaiws-kloeber-layout/eaiws-kloeber-layout.component';
import { CatalogUiListComponent } from './components/catalog/catalog-ui-list/catalog-ui-list.component';
import { BasketButtonsComponent } from './components/basket/basket-buttons/basket-buttons.component';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);
registerLocaleData(localeFr, 'fr-FR', localeFrExtra);
registerLocaleData(localeNl, 'nl-NL', localeNlExtra);
registerLocaleData(localeIt, 'it-IT', localeItExtra);
registerLocaleData(localeEs, 'es-ES', localeEsExtra);
@NgModule({
    declarations: [
        AppComponent,
        BasketFolderComponent,
        BasketUIComponent,
        EaiwsBaseLayoutComponent,
        EaiwsKloeberLayoutComponent,
        PropertyUIComponent,
        PageInteractiveAbComponent,
        PageNotFoundComponent,
        PageKloeberConfiguratorComponent,
        CatalogUIComponent,
        PropertySingleComponent,
        ViewerUIComponent,
        FormKloeberComponent,
        PageKloeberMesseComponent,
        MesseUiComponent,
        PageLanguageComponent,
        LanguageSwitchComponent,
        PconFactsUiComponent,
        AdditionallyUiComponent,
        CatalogUiListComponent,
        PageSedusConfiguratorComponent,
        BasketButtonsComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        AppRoutingModule,
        AngularSplitModule,
        BrowserAnimationsModule,
        MatInputModule,
        MatButtonModule,
        MatCheckboxModule,
        MatTooltipModule,
        MatTabsModule,
        MatSelectModule,
        FormsModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

<div [className]="getClass()">

    <div *ngIf="!articleData" class="loading">
        {{"message.selectItem" | translate}}
    </div>

    <div *ngIf="articleData" class="loaded">
        <strong>{{"title.article" | translate}}</strong>: {{articleData.baseArticleNumber}}<br>
        <strong>{{"label.description" | translate}}</strong>: {{articleData.longText}}

        <div *ngIf="options.showConfiguration">
            <h3>{{"title.configuration" | translate}}</h3>
            <div *ngIf="articleData" class="properties">
                <div class="metaProperties propContainer" *ngIf="hasMetaProperties">
                    <ng-container *ngFor="let item of choiceLists">
                        <app-property-single *ngIf="item.show && item.choiceList.propClass == 'MetaProperties'" [choiceListProperty]="item" [itemID]="itemID" [options]="options"></app-property-single>
                    </ng-container>
                </div>

                <div class="normalProperties propContainer">
                    <ng-container *ngFor="let item of choiceLists">
                        <app-property-single *ngIf="item.show && item.choiceList.propClass != 'MetaProperties'" [choiceListProperty]="item" [itemID]="itemID" [options]="options"></app-property-single>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
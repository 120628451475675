import { Component, Input, OnDestroy } from '@angular/core';
import { DownloadActionOBX, DownloadType } from 'src/app/models/downloadAction';
import { EaiwsOptions } from 'src/app/models/options';
import { BasketFolderService } from 'src/app/services/basket-folder.service';
import { EaiwsService } from 'src/app/services/eaiws.service';
import { PropertyService } from 'src/app/services/property.service';

@Component({
    selector: 'app-basket-buttons',
    templateUrl: './basket-buttons.component.html',
    styleUrls: ['./basket-buttons.component.scss']
})
export class BasketButtonsComponent implements OnDestroy {
    obxDownloadAction

    @Input() options: EaiwsOptions = {
        canArticleDelete: false
    }


    constructor(private folderService: BasketFolderService, private eaiwsService: EaiwsService, private propertyService: PropertyService) {


        this.obxDownloadAction = new DownloadActionOBX("OBX", DownloadType.OBX, this.eaiwsService.eaiwsSession.basket)
    }

    ngOnDestroy(): void {

    }


    onClickCallToAction() {
        this.folderService.callToActionEmitter.next("next")
    }

    getClass() {
        return "outterButtons " + this.options.designClass
    }
}

import * as eaiws from '@egr/wcf/modules/eaiws';
import * as core from '@egr/wcf/modules/core';
import * as cf from '@egr/wcf/modules/cf';
import { MainArticleElement } from '@egr/wcf/modules/cf';
import { InsertInfo } from '@egr/wcf/modules/eaiws/basket';




export class CustomArticleManager {
    private articleManager: cf.ArticleManager
    private currentElement: cf.MainArticleElement | undefined


    constructor(private coreApp: core.Application, private eaiwsSession: eaiws.EaiwsSession) {
        this.articleManager = new cf.ArticleManager(coreApp, eaiwsSession);

    }

    async load() {
        await this.articleManager.initializeSession({ oapPlanningMode: false });
        this.articleManager.setGfjBasketIdsEnabled(true);
    }


    addNewArticleFromBasket(itemID: string) {
        return new Promise((resolve, reject) => {
            this.articleManager.createArticleById(itemID).then(element => {
                this.resetModel()
                this.currentElement = element
                this.coreApp.model.addElement(element)
                resolve("")
            }).catch((error) => {
                reject(error)
            })
        })

    }

    async addNewArticle(options: InsertInfo) {
        this.resetModel()
        this.currentElement = await this.articleManager.insertArticle(options)
        this.coreApp.model.addElement(this.currentElement)
    }

    public resetModel() {
        if (!this.currentElement) return

        this.coreApp.model.removeElement(this.currentElement)
    }

    updateGeometry() {
        if (!this.currentElement) return Promise.reject()

        return this.currentElement.updateGeometry()
    }

    public switchDimonsionBox() {
        if (!this.currentElement) return

        this.currentElement.showDimensions = !this.currentElement.showDimensions
    }

    public isDimonsonBoxActive() {
        if (!this.currentElement) return false

        return this.currentElement.showDimensions
    }
}

import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { GetItemPropertiesOptions } from '@egr/wcf/modules/eaiws/basket';
import { BasketFolderService } from 'src/app/services/basket-folder.service';
import { EaiwsService } from 'src/app/services/eaiws.service';
import { ViewerService } from 'src/app/services/viewer.service';
import { BasketFolder } from '../basket-ui/basket-ui.component';
import { EaiwsOptions } from 'src/app/models/options';

@Component({
    selector: 'app-basket-folder',
    templateUrl: './basket-folder.component.html',
    styleUrls: ['./basket-folder.component.scss']
})
export class BasketFolderComponent implements OnInit, OnDestroy {
    currentFolder: BasketFolder | undefined
    selectedItemID: string = ""
    isOpen = false

    private itemClicked

    @Input() firstFolder = false
    @Input() level = 0
    @Input() options: EaiwsOptions = {
        canArticleDelete: false
    }
    @Input() set folder(value: BasketFolder | undefined) {
        this.currentFolder = value

        if (value) {
            const option = new GetItemPropertiesOptions()
            option.positionNumbers = true
        }
    }


    constructor(private eaiwsService: EaiwsService, private folderService: BasketFolderService, private viewerService: ViewerService, private host: ElementRef<HTMLElement>) {
        this.itemClicked = this.folderService.itemClickedEmitter.subscribe(async (itemID) => {
            if (!this.eaiwsService.articleManager) return
            if (!this.currentFolder) return
            if (!this.currentFolder.items.find(item => item.current.itemId == itemID)) return

            this.viewerService.showLoading.next(true)

            await this.eaiwsService.articleManager.addNewArticleFromBasket(itemID)
            this.viewerService.resetCamera()
            this.viewerService.resizeView()

            this.selectedItemID = itemID

            this.viewerService.showLoading.next(false)
        })
    }

    ngOnInit(): void {
        if (this.firstFolder) {
            this.isOpen = true
        }
    }

    ngOnDestroy(): void {
        this.itemClicked.unsubscribe()
    }


    async onClickItem(itemID: string) {
        this.folderService.itemClickedEmitter.next(itemID)
    }

    onClickFolder() {
        this.isOpen = !this.isOpen
    }

    onClickDeleteArticle(itemID: string) {
        if (!this.currentFolder) return
        if (!this.eaiwsService.articleManager) return

        this.currentFolder.items = this.currentFolder.items.filter(val => val.current.itemId != itemID)

        this.eaiwsService.eaiwsSession.basket.deleteItems([itemID])
        this.eaiwsService.articleManager.resetModel()
        this.folderService.itemDeleteEmitter.next(itemID)
    }

    onClickDeleteFolder() {
        if (!this.currentFolder) return
        if (!this.eaiwsService.articleManager) return

        this.eaiwsService.eaiwsSession.basket.deleteItems([this.currentFolder.current.itemId,].concat(this.currentFolder.items.map(item => item.current.itemId)))
        this.eaiwsService.articleManager.resetModel()
        this.folderService.itemDeleteEmitter.next(this.currentFolder.current.itemId)
        this.host.nativeElement.remove()
    }

    getClassName() {
        return "folder " + this.options.designClass + " " + (this.firstFolder ? "first" : "")
    }
}
